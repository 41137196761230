import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
  InputGroup,
} from "reactstrap"
import { useFormik, useFormikContext } from "formik"
import * as Yup from "yup";
import i18n from 'i18next';
import { WomsServiceContext } from "services/womsService";
import { CURRENCY_CODE, STORE_SYNC_TYPE, DASHBOARD_PERIOD_TYPE } from "services/womsEnum";
import moment from "moment";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css';
import cf from "../../services/codeFormatter"


const DashboardParamsModal = props => {
  const { show, onClose, params } = props;
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      "periodType" : DASHBOARD_PERIOD_TYPE.LAST_30_DAYS,
      "fromDate" : '',
      'toDate' : ''
    },
    validationSchema: Yup.object({
      periodType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      fromDate: Yup.date().when('periodType', {
        is: periodType => periodType == DASHBOARD_PERIOD_TYPE.RANGE,
        then: Yup.date()
          .required(i18n.t("VALIDATION.FROM_DATE_REQUIRED")),
        otherwise: Yup.date()
      }),
      toDate: Yup.date().when('periodType', {
        is: periodType => periodType == DASHBOARD_PERIOD_TYPE.RANGE,
        then: Yup.date()
          .required(i18n.t("VALIDATION.TO_DATE_REQUIRED")),
        otherwise: Yup.date()
      })
    }),

    onSubmit: (values) => {
          onClose(values);
    },
  });

  useEffect(() => {
    if (show) {
      if (params) {
          formik.setValues(params);
      }
    }
  }, [show]);


  return (
    <Modal className="" style={{ color: "yellow"}} isOpen={show} size="md">
      <ModalHeader tag="h4">
        {i18n.t("DASHBOARD.SETTINGS")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label"> {i18n.t("DASHBOARD.PERIOD_TYPE")}</Label>

                <Input
                  name="periodType"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.periodType || ""
                  }
                  invalid={
                    formik.touched.periodType && formik.errors.periodType ? true : false
                  }
                >
                  {
                    Object.values(DASHBOARD_PERIOD_TYPE).map(val =>
                      <option value={val} key={val}>{cf.format("DashboardPeriodType",val)}</option>
                    )
                  }
                </Input>
                {formik.touched.periodType && formik.errors.periodType ? (
                  <FormFeedback type="invalid">{formik.errors.periodType}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        {formik.values.periodType == DASHBOARD_PERIOD_TYPE.RANGE && <Row>
          <Col md="12">
              <div className="mb-3">
                <Label>{i18n.t("DASHBOARD.DATE_RANGE")}</Label>
                <InputGroup>
                    <Flatpickr
                        className="form-control d-block"
                        placeholder={i18n.t("DASHBOARD.SELECT_DATE_RANGE")}
                        options={{
                            mode: "range",
                            dateFormat: "Y-m-d"
                        }}
                        value={[formik.values.fromDate, formik.values.toDate]}
                        onChange={([from, to]) => {
                            formik.setFieldValue('fromDate', from);
                            formik.setFieldValue('toDate', to);
                        }}
                        onBlur={() => {
                          formik.setFieldTouched('fromDate');
                          formik.setFieldTouched('toDate');
                        }}
                    />
                  </InputGroup>
                  {formik.errors.fromDate ? (
                  <div className="text-danger">
                  <small>{formik.errors.fromDate}</small>
                </div>
                ) : null}
                {formik.errors.toDate ? (
                  <div className="text-danger">
                  <small>{formik.errors.toDate}</small>
                </div>
                ) : null}
                
                </div>
                
                </Col>
                
          </Row>}
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col md="12">
              <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()}>{i18n.t("CANCEL")}</button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success save-integration"
                >
                  {i18n.t("SAVE")}
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
  )
}
export default DashboardParamsModal;