import React, { useContext, useEffect, useState } from 'react'
import {
    Button,
    Col,
    Row,
    Label,
    Card,
    CardTitle,
    CardHeader,
    CardImg,
    CardBody,
    Container,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Table,
    CardText,
} from 'reactstrap'
import { withTranslation } from "react-i18next";
import { useParams, useNavigate } from 'react-router';
import { WomsServiceContext } from 'services/womsService';
import { formatToCurrency, getOrderFulfillmentStatusCssClasses, getOrderStatusCssClasses, getStoreIcon } from '../OrderUIHelper';
import cf from "../../../services/codeFormatter";
import i18n from 'i18next';
import LinearProgress from 'components/Common/LinearProgress';
import { EDIT_MODAL_TYPE, ORDER_FEE_TYPE, STORE_SYNC_TYPE } from 'services/womsEnum';
import ActivityLogs from '../../ActivityLog/ActivityLogs';
import { format } from 'date-fns'
import { calculateSubtotal, calculateTotalTax, calculateTotalAmount } from '../helpers/orderCalculations'
import { TableContainerBordered } from 'components/Common/TableContainerBordered';
import { CardContent } from '@mui/material';
import { useOrderLineItemsThumbnails } from 'hooks/useOrderLineItemsThumbnails';
import { ItemsModal } from '../Edit/ItemsModal';
import { DetailsModal } from '../Edit/DetailsModal';
import { NotesModal } from '../Edit/NotesModal';
import { AddressModal } from '../Edit/AddressModal';
import OrderFulfillments from '../OrderFulfillments/OrderFulfillments';

const OrderDetail = (props) => {
    document.title = i18n.t("ORDER_DETAIL");
    let { id } = useParams();
    let navigate = useNavigate();
    const { womsSvc } = useContext(WomsServiceContext);
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState({});

    const [activeModal, setActiveModal] = useState(``);

    const { thumbnails } = useOrderLineItemsThumbnails({order});

    const orderExists = () => order && order.id;

    const handleEditModal = (modalType) => {
        setActiveModal(modalType);
    };  

    useEffect(() => {

        if (id) {
            setIsLoading(true);
            womsSvc.getOrder(id).then(({ data }) => {
                setOrder(data);
                setIsLoading(false);
            });
        }

    }, [id]);

    const editModals = () => {
        return (
            <>
            <ItemsModal 
            values={order}
            setValues={setOrder}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
            />
            <DetailsModal 
            order={order}
            setOrder={setOrder}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
            />
            <NotesModal
            order={order}
            setOrder={setOrder}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
            />

            <AddressModal 
            order={order}
            setOrder={setOrder}
            activeModal={activeModal}
            setActiveModal={setActiveModal}
            // editModalType={editModalType}
            />
            </>

        );
    };

    return (
        <>  
            {
                orderExists() &&
                editModals()
            }
            <div className='page-content '>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <div style={{ display: "flex" }}>
                                    <Button
                                        type="button"
                                        className="btn btn-light btn-sm mt-1"
                                        onClick={() => {
                                            navigate('/salesOrder');
                                        }}
                                    >
                                        <i className="bx bx-arrow-back" />
                                    </Button>
                                    <h4 className="mb-0 pt-2 ms-2 font-size-18">{"#SO" + id}</h4>
                                    <span className={getOrderStatusCssClasses(order.status) + " mt-3 ms-2"} >{cf.format("OrderStatus", order.status)}</span>
                                    <span className={getOrderFulfillmentStatusCssClasses(order.fulfillmentStatus) + " mt-3 ms-2"} >{cf.format("OrderFulfillmentStatus", order.fulfillmentStatus)}</span>

                                </div>
                                <div className="page-title-right">
                                    <Button
                                        disabled={isLoading}
                                        type="button"
                                        className="btn btn-light btn-sm mt-1 ms-1"
                                        onClick={() => {
                                        }}
                                    >
                                        {i18n.t("ORDER_REFUND")}
                                    </Button>
                                    <Button
                                        disabled={isLoading}
                                        type="button"
                                        className="btn btn-light btn-sm mt-1 ms-1"
                                        onClick={() => {
                                        }}
                                    >
                                        {i18n.t("ORDER_CANCEL")}
                                    </Button>
                                    <UncontrolledButtonDropdown>
                                        <Button disabled={isLoading} className="btn btn-secondary btn-sm mt-1 ms-2" onClick={() => {
                                        }}>
                                            {i18n.t("MORE_ACTIONS")}
                                        </Button>
                                        <DropdownToggle caret
                                            className="btn btn-secondary btn-sm mt-1">
                                            <i className="mdi mdi-chevron-down" />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={() => {
                                            }}>{i18n.t("ORDER_RETURN")}</DropdownItem>
                                            <DropdownItem onClick={() => {
                                            }}>{i18n.t("ORDER_DELETE")}</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {isLoading && <LinearProgress />}
                    <Row>
                        <Col md={8}>
                            <Row>
                                <Col>
                                    <Card>

                                        <CardBody>
                                        <CardTitle>
                                            {i18n.t("ORDER_DETAILS")}
                                            <Button
                                                // disabled={isLoading || order?.store?.syncType === STORE_SYNC_TYPE.PASSIVE}
                                                type="button"
                                                className="btn btn-light btn-sm woms-edit-btn  "
                                                onClick={()=> handleEditModal(EDIT_MODAL_TYPE.ORDER_DETAILS)}
                                            >
                                                <i className="bx bx-pencil" />
                                            </Button>
                                        </CardTitle>
                                        <div className='card-content'>
                                            
                                            <Row>
                                                <Col>
                                                    <Row className='mb-3'>
                                                        <Col>
                                                            <span className="fw-bold">{i18n.t("ORDER_CUSTOMER_ORDER_NUMBER")}</span>
                                                            <div>{order.orderNo}</div>
                                                        </Col>

                                                        <Col>
                                                            <Row>
                                                                <Col md={12} className='d-flex '>
                                                                    <img height="35px" src={getStoreIcon(order.store?.platformType)} alt={i18n.t("STORE_ICON")} />
                                                                    <div className="ms-2"><span className="fw-bold">{i18n.t("STORE")}</span>
                                                                        <div>{order.store?.name}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <span className="fw-bold">{i18n.t("ORDER_CURRENCY")}</span>
                                                            <div>{order.currency}</div>
                                                        </Col>
                                                        <Col>
                                                            <span className="fw-bold">{i18n.t("ORDERED_ON")}</span>
                                                            <div>{order.orderedOn ? format(new Date(order.orderedOn.split("T")[0].replace(/-/g, '\/')), "MMM d, yyyy") : '-'}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </div>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>

                                        <CardBody className='card-body'>
                                            <CardTitle>
                                                {i18n.t("ORDER_ITEMS")}<Button
                                                    disabled={isLoading}
                                                    // disabled={isLoading || order?.store?.syncType === STORE_SYNC_TYPE.PASSIVE}
                                                    type="button"
                                                    className="btn btn-light btn-sm woms-edit-btn"
                                                    onClick={()=> handleEditModal(EDIT_MODAL_TYPE.ORDER_ITEMS)}
                                                >
                                                    <i className="bx bx-pencil" />
                                                </Button>
                                            </CardTitle>

                                            <Table className="table table-centered table-nowrap woms-lineitem-table ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="fw-bold">{i18n.t("PRODUCT")}</th>
                                                        <th scope="col" className="fw-bold">{i18n.t("PRODUCT_NAME")}</th>
                                                        <th scope="col" className="fw-bold">{i18n.t("PRICE")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {order.orderLineItems?.map(item => (
                                                        <tr key={item.id}>
                                                            <th scope="row">
                                                                <div>
                                                                    {thumbnails.find(t=> t.sku ==  item.storeListing.storeSku) ? <img src={thumbnails.find(t=> t.sku ==  item.storeListing.storeSku).thumbnailUrl}/> : <i className="bx bx-image-alt woms-product-placeholder" />}
                                                                </div>
                                                            </th>
                                                            <td>
                                                                <div>
                                                                    <span className="text-wrap">
                                                                        {item.storeListing.name}
                                                                    </span>
                                                                    <p className="text-muted mb-0">{formatToCurrency(item.price, order.currency)} x {item.quantity} ({thumbnails.find(t=> t.sku ==  item.storeListing.storeSku) 
                                                                        ? <a href={"/product/"+thumbnails.find(t=> t.sku ==  item.storeListing.storeSku).id}>{item.storeListing.storeSku}</a> 
                                                                        : item.storeListing.storeSku})
                                                                    </p>
                                                                    {item.discountLineItems?.length > 0 && item.discountLineItems.map(dl => 
                                                                    <p className="text-muted mb-0" key={dl.id}>
                                                                        {"Discount : -" + formatToCurrency(dl.amount, order.currency)} {dl.name && "(" + dl.name + ")"}
                                                                    </p>
                                                                    )}
                                                                    {item.feeLineItems?.length > 0 && item.feeLineItems.map(fl => 
                                                                    <p className="text-muted mb-0" key={fl.id}>
                                                                        {"Fee : " + formatToCurrency(fl.amount, order.currency)} {fl.name && "(" + fl.name + ")"}
                                                                    </p>
                                                                    )}
                                                                    {item.taxLineItems?.length > 0 && item.taxLineItems.map(tl => 
                                                                    <p className="text-muted mb-0" key={tl.id}>
                                                                        {"Tax : " + formatToCurrency(tl.amount, order.currency)} {tl.name && "(" + tl.name + ")"}
                                                                    </p>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td>{formatToCurrency(item.price * item.quantity, order.currency)}</td>

                                                        </tr>
                                                    ))}

                                                    <tr>
                                                        <td colSpan="2">
                                                            <h6 className="m-0 text-end fw-bold">{i18n.t("PRICE_SUBTOTAL")}:</h6>
                                                        </td>
                                                        <td>{formatToCurrency(calculateSubtotal(order), order.currency)}</td>
                                                    </tr>
                                                    {order.discountLineItems?.length > 0 && <tr>
                                                        <td colSpan="2">
                                                            <h6 className="m-0 text-end fw-bold">{i18n.t("PRICE_ADDITIONAL_DISCOUNT")}:</h6>
                                                        </td>
                                                        <td>{order.discountLineItems.map(dl => <p className="text-muted mb-0" key={dl.id}>{"-" + formatToCurrency(dl.amount, order.currency)} {dl.name && <div>{"(" + dl.name + ")"}</div>}</p>)}</td>
                                                    </tr>}
                                                    {calculateTotalTax(order) > 0 && <tr>
                                                        <td colSpan="2">
                                                            <h6 className="m-0 text-end fw-bold">{i18n.t("PRICE_TAX")}:</h6>
                                                        </td>
                                                        <td>{formatToCurrency(calculateTotalTax(order), order.currency)}</td>
                                                    </tr>}

                                                    {order.feeLineItems?.filter(fl => fl.type == ORDER_FEE_TYPE.SHIPPING).length > 0 && <tr>
                                                        <td colSpan="2">
                                                            <h6 className="m-0 text-end fw-bold">{i18n.t("PRICE_SHIPPING")}:</h6>
                                                        </td>
                                                        <td>{order.feeLineItems.filter(fl => fl.type == ORDER_FEE_TYPE.SHIPPING).map(dl => <p className="text-muted mb-0" key={dl.id}>{formatToCurrency(dl.amount, order.currency)} {dl.name && <div>{"(" + (dl.name && dl.name) + ")"}</div>}</p>)}</td>
                                                    </tr>}
                                                    {order.feeLineItems?.filter(fl => fl.type != ORDER_FEE_TYPE.SHIPPING).length > 0 && <tr>
                                                        <td colSpan="2">
                                                            <h6 className="m-0 text-end fw-bold">{i18n.t("PRICE_FEE")}:</h6>
                                                        </td>
                                                        <td>{order.feeLineItems.filter(fl => fl.type != ORDER_FEE_TYPE.SHIPPING).map(dl => <p className="text-muted mb-0" key={dl.id}>{formatToCurrency(dl.amount, order.currency)} {dl.name && <div>{"(" + (dl.name && dl.name) + ")"}</div>}</p>)}</td>
                                                    </tr>}
                                                    <tr>
                                                        <td colSpan="2">
                                                            <h6 className="m-0 text-end fw-bold">{i18n.t("PRICE_TOTAL")}:</h6>
                                                        </td>
                                                        <td>{formatToCurrency(calculateTotalAmount(order), order.currency)}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <OrderFulfillments orderId={order.id} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <ActivityLogs key={order.updatedOn} orderId={order.id} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>{i18n.t("PRODUCT_NOTES")}<Button
                                                // disabled={isLoading || order?.store?.syncType === STORE_SYNC_TYPE.PASSIVE}
                                                disabled={isLoading}

                                                type="button"
                                                className="btn btn-light btn-sm woms-edit-btn"
                                                onClick={()=> handleEditModal(EDIT_MODAL_TYPE.ORDER_NOTES)}
                                            >
                                                <i className="bx bx-pencil" />
                                            </Button></CardTitle>
                                            <span className="fw-bold">{i18n.t("PRODUCT_REFERENCE_NUMBER")}</span>
                                            <div>{order.referenceNo}</div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card>

                                        <CardBody>
                                            <CardTitle>{i18n.t("SHIPPING_ADDRESS")}<Button
                                                disabled={isLoading}
                                                type="button"
                                                className="btn btn-light btn-sm woms-edit-btn"
                                                onClick={()=> handleEditModal(EDIT_MODAL_TYPE.ORDER_SHIPPING_ADDRESS)}
                                            >
                                                <i className="bx bx-pencil" />
                                            </Button></CardTitle>
                                            <div>{(order.shippingAddress?.firstName || "") + (order.shippingAddress?.lastName ? " " + order.shippingAddress?.lastName : "")}</div>
                                            <div>{order.shippingAddress?.phone || i18n.t("ADDRESS_NO_PHONE_NUMBER")}</div>
                                            <div>{order.shippingAddress?.company || ""}</div>
                                            <div>{order.shippingAddress?.address1 || ""}</div>
                                            <div>{order.shippingAddress?.address2 || ""}</div>
                                            <div>{order.shippingAddress?.city || ""}&nbsp;{order.shippingAddress?.province || ""}&nbsp;{order.shippingAddress?.postalCode || ""}</div>
                                            <div>{order.shippingAddress?.country || ""}</div>
                                            <br></br>
                                            <CardTitle>{i18n.t("BILLING_ADDRESS")}<Button
                                                // disabled={isLoading || order?.store?.syncType === STORE_SYNC_TYPE.PASSIVE}
                                                disabled={isLoading}
                                                type="button"
                                                className="btn btn-light btn-sm woms-edit-btn"
                                                onClick={()=> handleEditModal(EDIT_MODAL_TYPE.ORDER_BILLING_ADDRESS)}
                                            >
                                                <i className="bx bx-pencil" />
                                            </Button></CardTitle>
                                            <div>{(order.billingAddress?.firstName || "") + (order.billingAddress?.lastName ? " " + order.billingAddress?.lastName : "")}</div>
                                            <div>{order.billingAddress?.phone || i18n.t("ADDRESS_NO_PHONE_NUMBER")}</div>
                                            <div>{order.billingAddress?.company || ""}</div>
                                            <div>{order.billingAddress?.address1 || ""}</div>
                                            <div>{order.billingAddress?.address2 || ""}</div>
                                            <div>{order.billingAddress?.city || ""}&nbsp;{order.billingAddress?.province || ""}&nbsp;{order.billingAddress?.postalCode || ""}</div>
                                            <div>{order.billingAddress?.country || ""}</div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default withTranslation()(OrderDetail)
