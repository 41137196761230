import { FULFILLMENT_STATUS, ORDER_FULFILLMENT_STATUS, ORDER_STATUS, USER_STATUS } from "services/womsEnum";

  
  export function getFulfillmentStatusCssClasses(status) {
    var type;
    switch(status) {
      case FULFILLMENT_STATUS.DRAFT:
      type="secondary"
      break;
      case FULFILLMENT_STATUS.SUBMITTED:
      type="primary"
      break;
      case FULFILLMENT_STATUS.PICKED:
      type="warning"
      break;
      case FULFILLMENT_STATUS.PACKED:
      type="info"
      break;
      case FULFILLMENT_STATUS.PARTIALLY_SHIPPED:
      type="pink"
      break;
      case FULFILLMENT_STATUS.SHIPPED:
      type="success"
      break;
      case FULFILLMENT_STATUS.CANCELLED:
      type="danger"
      break;
      case FULFILLMENT_STATUS.DELETED:
      type="dark"
      break;
      default:
        type=""
    }

    return `badge badge-soft-${
      type
    }`;
  }
  

  
export function generateTrackingUrl(trackingNumber) {
  const carrier = identifyCarrier(trackingNumber);

  switch(carrier) {
    case 'UPS':
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNumber}`;
    case 'FedEx':
      return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`;
    case 'USPS':
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`;
    default:
      return '';
  }
}

export function identifyCarrier(trackingNumber) {
  if (/^1Z/.test(trackingNumber)) {
    return 'UPS';
  } else if (/^\d{12,15}$/.test(trackingNumber)) {
    return 'FedEx';
  } else if (/^\d{20,22}$/.test(trackingNumber) || /^[A-Z]{2}\d{9}US$/.test(trackingNumber)) {
    return 'USPS';
  } else {
    return 'Unknown';
  }
};