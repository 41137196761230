import React, { useContext, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  Form,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap"
import { useFormik, useFormikContext } from "formik"
import * as Yup from "yup";
import i18n from 'i18next';
import cf from "../../services/codeFormatter";
import { WomsServiceContext } from "services/womsService";
import { CURRENCY_CODE, PLATFORM_TYPE, STORE_SYNC_TYPE } from "services/womsEnum";
import BrandModal from "pages/Brand/BrandModal";

const StoreModal = props => {
  const { show, isEdit, id, onClose } = props;
  const { womsSvc } = useContext(WomsServiceContext);
  const [isLoading, setIsLoading] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [brandModal, setBrandModal] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      id: '',
      name: '',
      isActive: true,
      platformType: '',
      syncType: '',
      url: '',
      defaultBrandId: ''
    },
    validationSchema: Yup.object({
      name: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      platformType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
      syncType: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
    }),

    onSubmit: (values) => {
      if (isEdit) {
        womsSvc.updateStore(values).then(({ data }) => {
          formik.resetForm();
          onClose(true);
        });
      } else {
        womsSvc.createStore(values).then(({ data }) => {
          formik.resetForm();
          onClose(true);
        });
      }
    },
  });

  useEffect(() => {
    if (show) {
      refreshBrandList();
      if (id) {
        setIsLoading(true);
        womsSvc.getStore(id).then(({ data }) => {
          formik.setValues(data);
          setIsLoading(false);
        });
      }
    } else {
      formik.resetForm();
    }
  }, [show]);

  const refreshBrandList = () => {
    setIsLoading(true);
    womsSvc.getBrands().then(({ data }) => {
      setBrandList(data);
      setIsLoading(false);
    });
  }

  const handleBrandModalClose = (isUpdated) => {
    if (isUpdated) {
      refreshBrandList();
    }
    setBrandModal(false);
  }

  return (<>
    <Modal isOpen={show} size="lg">
      <ModalHeader tag="h4">
        {!!isEdit ? i18n.t("EDIT_STORE") : i18n.t("ADD_NEW_STORE")}
      </ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
          return false;
        }}
      >
        <ModalBody>
          <Row>
            {isEdit && <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("ID")}</Label>
                <Input
                  name="id"
                  type="text"
                  readOnly
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.id ? ("#ST" + formik.values.id) : ""}
                  invalid={
                    formik.touched.id && formik.errors.id ? true : false
                  }
                  disabled={true}
                />
                {formik.touched.id && formik.errors.id ? (
                  <FormFeedback type="invalid">{formik.errors.id}</FormFeedback>
                ) : null}
              </div></Col>}
          </Row>
          <Row>
            <Col md="12">
              <div className="mb-3">
                <Label className="form-label">{i18n.t('NAME')}</Label>
                <Input
                  name="name"
                  type="text"
                  placeholder={i18n.t("STORE_NAME")}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name || ""}
                  invalid={
                    formik.touched.name && formik.errors.name ? true : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormFeedback type="invalid">{formik.errors.name}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div className="mb-3">
                <Label className="form-label">{i18n.t('URL')}</Label>
                <Input
                  name="url"
                  type="text"
                  placeholder="url"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.url || ""}
                  invalid={
                    formik.touched.url && formik.errors.url ? true : false
                  }
                  disabled={isLoading}
                />
                {formik.touched.url && formik.errors.url ? (
                  <FormFeedback type="invalid">{formik.errors.url}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="6">
            <div className="mb-3">
                  <Label className="form-label">{i18n.t('DEFAULT_BRAND')}</Label>
                  <div className="d-flex flex-row">
                    <Input
                      name="defaultBrandId"
                      type="select"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.defaultBrandId || ""}
                      invalid={formik.touched.defaultBrandId && formik.errors.defaultBrandId ? true : false}
                      disabled={isLoading}
                    >
                      <option key={''} value={''}>
                        {i18n.t('NOT_SPECIFIED')}
                      </option>
                      {brandList.map(el => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                      
                    </Input>
                    &nbsp;
                    <Button outline onClick={() => { setBrandModal(true) }}>
                      {formik.values.defaultBrandId ? <i className="mdi mdi-pencil" /> : <i className="mdi mdi-plus" />}
                    </Button>
                  </div>
                  {formik.touched.defaultBrandId && formik.errors.defaultBrandId ? (
                    <FormFeedback type="invalid">{formik.errors.defaultBrandId}</FormFeedback>
                  ) : null}
                </div>
            </Col>
          </Row>
          <Row>
            <Col md="5">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("PLATFORM_TYPE")}</Label>

                <Input
                  name="platformType"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.platformType || ""
                  }
                  invalid={
                    formik.touched.platformType && formik.errors.platformType ? true : false
                  }
                >
                  <option value="">{i18n.t("SELECT_PLATFORM_TYPE")}</option>
                  {
                    Object.values(PLATFORM_TYPE).map(val =>
                      <option value={val} key={val}>{cf.format("PlatformType", val)}</option>
                    )
                  }
                </Input>
                {formik.touched.platformType && formik.errors.platformType ? (
                  <FormFeedback type="invalid">{formik.errors.platformType}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md="5">
              <div className="mb-3">
                <Label className="form-label">{i18n.t("SYNC_TYPE")}</Label>

                <Input
                  name="syncType"
                  type="select"
                  className="form-select"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={
                    formik.values.syncType || ""
                  }
                  invalid={
                    formik.touched.syncType && formik.errors.syncType ? true : false
                  }
                >
                  <option value="">{i18n.t("SELECT_STORE_SYNC_TYPE")}</option>
                  {
                    Object.values(STORE_SYNC_TYPE).map(val =>
                      <option value={val} key={val}>{cf.format("StoreSyncType", val)}</option>
                    )
                  }
                </Input>
                {formik.touched.syncType && formik.errors.syncType ? (
                  <FormFeedback type="invalid">{formik.errors.syncType}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col md="2">

              <div className="mb-3">
                <Label className="form-label">{i18n.t("ACTIVE")}</Label><br />

                <Input
                  type="checkbox"
                  className="form-check-input"
                  name="isActive"
                  checked={formik.values.isActive ?? false}
                  onClick={e => {
                    formik.setFieldValue('isActive', !formik.values.isActive);
                  }}
                  onChange={e => {
                  }}
                />
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col md="12">
              <div className="text-end mt-2">
                <button type="button" className="btn btn-secondary" onClick={() => onClose()} disabled={isLoading}>{i18n.t("CANCEL")}</button>
                &nbsp;&nbsp;
                <button
                  type="submit"
                  className="btn btn-success save-integration"
                  disabled={isLoading}
                >
                  {i18n.t("SAVE")}
                </button>
              </div>
            </Col>
          </Row>
        </ModalFooter>
      </Form>
    </Modal>
    <BrandModal
        show={brandModal}
        id={formik.values.defaultBrandId}
        onClose={handleBrandModalClose}
      />
      </>
  )
}
export default StoreModal;